<template lang="pug">
    .main-wrapper.tipoCheckup-salvar

        BuscarProcedimento

        ProgressBar(v-show='waiting' mode="indeterminate")
        div(v-show='! waiting')
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /&nbsp;
                            router-link(:to='{ name: "tipo-checkup" }') Tipo de Checkup
                            span &nbsp;/&nbsp;
                            b {{  model.id ? 'Editar' : 'Adicionar' }}

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-show='waitingForm' mode="indeterminate")
                div(v-show='! waitingForm')
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Tipo de Checkup`")
                        .p-grid.p-fluid.p-justify-center

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_checkup.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='$v.model.ds_checkup.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_checkup.$error')
                                    .form-message--error(v-if="!$v.model.ds_checkup.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_checkup.required") <b>Descrição</b> é obrigatório.

                            .p-col-12
                                label.form-label Observações:
                                Textarea.textarea-informacoes(v-model='model.ds_observacao' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12.p-md-6
                                span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                            .p-col-12.p-md-6
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-12.p-md-6
                                span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                            .p-col-12.p-md-6
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12
                                Panel(header='Grupo de procedimentos')

                                    .p-grid.p-fluid.p-justify-center(v-if='model.cd_procedimentos')

                                        .p-col-12(v-if='model.cd_procedimentos.length')
                                            DataTable(:value='model.cd_procedimentos')
                                                Column(headerStyle='width: 90%' header='Procedimento' field='ds_procedimento')
                                                Column(header='Ações')
                                                    template(#body='props')
                                                        .ta-center
                                                            Button.p-button-raised.p-button-rounded.p-button-danger(type='button' icon="jam jam-trash" @click='removerProcedimento(props)')

                                        .p-col-4.pt-2
                                            Button(icon='jam jam-search' label='Buscar procedimento' style='font-size:12px' type='button' @click='dialogBuscar = true')

                            .p-col-2
                                Button(icon='jam jam-save' label='Salvar' type="submit")
</template>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Button from 'primevue/button'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import Textarea from 'primevue/textarea'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'

    import { required, minLength } from 'vuelidate/lib/validators'
    import { Checkup } from '../../middleware'
    import BuscarProcedimento from './BuscarProcedimento'
    import moment from "moment"

    export default {
        components: { ProgressBar, Button, Panel, InputText, Textarea, BuscarProcedimento, DataTable, Column },
        created () {

            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                this.waiting = true
                Checkup.findTipoCheckup(id).then(response => {
                    this.waiting = false
                    if (response.status === 200) {
                        this.model.id = id
                        this.model.ds_checkup = response.data.ds_checkup
                        this.model.ds_observacao = response.data.ds_observacao
                        this.model.cd_procedimentos = response.data.cd_procedimentos
                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                        this.model.nm_usuario_edi = response.data.nm_usuario_edi
                        this.model.dt_criado_f = response.data.dt_criado ?
                            moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm') : ""
                        this.model.dt_atualizado_f = response.data.dt_atualizado ?
                            moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm') : ""
                    }
                })
            }

        },
        data () {
            return {
                model: {
                    ds_checkup: null,
                    ds_observacao: null,
                    cd_procedimentos: [],
                    cd_estabelecimento: null
                },
                waiting: false,
                waitingForm: false,
                submitted: false,
                dialogBuscar: false,
                name: 'saveTipoCheckup'
            }
        },
        validations () {
            let v = {
                model: {
                    ds_checkup: { required, minLength: minLength(2) }
                }
            }
            return v
        },
        methods: {
            handleSubmit () {

                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                if (! this.model.cd_procedimentos.length) {
                    this.$toast.error('Informe a lista de procedimentos do checkup', { duration: 3000 })
                    return 0
                }

                let dataSend = Object.assign({}, this.model)

                delete dataSend.cd_estabelecimento
                dataSend.cd_procedimentos = dataSend.cd_procedimentos.map(p => p.id)

                this.waitingForm = true
                Checkup.saveTipoCheckup(dataSend).then(response => {
                    this.waitingForm = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        if (! dataSend.id) this.$router.push({ name: 'tipo-checkup' })
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
			removerProcedimento (procedimento) {
				this.model.cd_procedimentos.splice(procedimento.index, 1)
			}
        }
    }
</script>