<template lang="pug">
	Dialog.buscarProcedimento(header='Pesquisar procedimento' :visible.sync='$parent.dialogBuscar' :modal='true')
		
		.ta-center(v-if='waiting')
			ProgressSpinner(strokeWidth='3')

		form(v-else @submit.prevent='buscarProcedimento()')
		
			.p-grid.p-fluid.p-align-end

				.p-col-12

					label.form-label Pesquisar:
					.p-inputgroup
						InputText(type='text' v-model='filters.ds_procedimento')
						Button(icon='jam jam-search')

				.p-col-12(v-if='procedimentos.length')

					DataTable(style='max-height:360px;overflow-y:auto' :value='procedimentos')
						Column(header='Procedimentos' field='nm_procedimento' bodyStyle='padding: 0')
							template(#body='props')
								.pa-1(style='cursor:pointer;font-size:12px;display:flex;align-items:center' @click='selecionarProcedimento(props.data)')
									.mr-1(style='display:inline;color:blue;font-weight:700' v-if="$parent?.model['cd_procedimentos']?.map(p => p.id).includes(props.data.id)")
										i.jam.jam-check
									.mr-1(style='display:inline;color:red;font-weight:700' v-else)
										i.jam.jam-close
									span {{ props.data.nm_procedimento }}
						
</template>

<style lang="scss">
	.buscarProcedimento {
		width: 100%;
		max-width: 680px;
		.p-dialog-content {
			max-height: 540px;
			overflow-y: auto !important;
		}
		.p-progress-spinner {
			width: 3.2em;
			height: auto;
			margin: 8em 0;
		}
	}
</style>

<script>
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import Button from 'primevue/button'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import ProgressSpinner from 'primevue/progressspinner'
	import { Procedimentos } from '../../middleware'

	export default {
		components: { Dialog, InputText, Button, DataTable, Column, ProgressSpinner },
		watch: {
			'$parent.dialogBuscar': function () {
				this.procedimentos = []
				this.filters.ds_procedimento = ''
			}
		},
		data () {
			return {
				procedimentos: [],
				waiting: false,
				filters: { ds_procedimento: '' }
			}
		},
		methods: {

			buscarProcedimento () {
				if (this.filters.ds_procedimento) {

					if (this.filters.ds_procedimento.length < 2) {
						this.$toast.error(`Digite pelo menos 2 caracteres para a pesquisa`, { duration: 3000 })
						return 0
					}
					
					this.procedimentos = []
					this.waiting = true
					
					Procedimentos.findAllClean({ ds_procedimento: this.filters.ds_procedimento }).then(response => {
						this.waiting = false;
						if(([200,201]).includes(response.status)){
							if (response.data.length) this.procedimentos = response.data
							else this.$toast.error(`Nenhum procedimento encontrado`, { duration: 3000 })
						}
					})
						
				} else this.$toast.info(`Digite o nome do procedimento para a pesquisa`, { duration: 3000 })
			},
			selecionarProcedimento (procedimento) {
				if(this.$parent.model.hasOwnProperty('cd_procedimentos')) { // eslint-disable-line
					//Alteração feita para inserir mais de um procedimento igual
					let idMap = this.$parent.model['cd_procedimentos'].map(p => p.id)
					if (idMap.includes(procedimento.id)) {
						this.$parent.model.cd_procedimentos = this.$parent.model['cd_procedimentos'].filter(p => p.id !== procedimento.id)
				
						this.$parent.$forceUpdate()
						return
					}
					this.$parent.model.cd_procedimentos.push(procedimento)
				}
				this.filters.ds_procedimento = null
				this.$parent.$forceUpdate()
			},
		}
	}
</script>